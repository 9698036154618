/* src/components/Footer.css */
.footer {
  background-color: #141414;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.footer p {
  margin: 0;
  font-size: 1rem;
}

.footer a {
  color: #6a0dad;
  text-decoration: none;
  transition: color 0.3s;
}

.footer a:hover {
  color: #fff;
}
