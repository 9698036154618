.skills {
  background-color: #1e1e1e;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.skill-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.skills h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #66fcf1;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

}

.skill-card {
  background-color: #222;
  padding: 20px 10px;
  border-radius: 15px;
  position: relative;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  flex: 1 1 calc(25% - 20px);
  box-sizing: border-box;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skill-card:hover {
  transform: translateY(-10px);
}

.skill-icon-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
  align-items: center;
}

.skill-card:hover .skill-icon-image {
  transform: scale(1.1);
}

.skill-name {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  color: #fff;
  font-size: 1.2rem;
  margin-top: 10px;
}

.skill-card:hover .skill-name {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .skill-card {
    flex: 1 1 calc(50% - 20px); /* Adjust width for 2 items per row */
  }
}

@media (max-width: 480px) {
  .skill-card {
    flex: 1 1 calc(100% - 20px); /* Adjust width for 1 item per row */
  }
}
