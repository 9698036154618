.services {
  background-color: #141414;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.services h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #66fcf1;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
  gap: 20px;
  justify-content: center; 
  align-items: center;
}

.service-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.service-content {
  max-width: 90%;
}

.service-content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-content p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}
