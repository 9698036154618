.education {
  background-color: #141414;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.education h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #66fcf1;
}

.education-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.education-item {
  width: 45%;
  background-color: #222;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  text-align: left;
}

.edu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.education-item h4 {
  color: #66fcf1;
  margin-bottom: 10px;
}

.education-item p {
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 1rem;
}

/* Bullet Points */
.education-item ul {
  list-style-type: disc;
  margin-top: 10px;
  padding-left: 20px; /* Adjusts the indentation */
}

.education-item li {
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
}

.education-item a {
  color: #66fcf1;
  text-decoration: underline;
}

.education-item a:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  .education-item {
    width: 100%;
  }
}
