.testimonials {
  background-color: #1e1e1e;
  color: #fff;
  padding: 80px 0;
  text-align: center;
}

.testimonials h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #66fcf1;
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 20px;
  max-width: 90%;
  margin: 0 auto;
  justify-content: center;
}

.testimonial-item {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 12px;
  font-size: 1.1rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  position: relative; 
}

.testimonial-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

.testimonial-photo {
  position: absolute;
  top: -30px;
  left: -5px;
  width: 100px; 
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #66fcf1;
}

.testimonial-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-text {
  margin-top: 50px; 
}

.testimonial-item p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #e6e6e6;
}

.testimonial-item h4 {
  font-size: 1rem;
  color: #66fcf1;
}

/* Media Queries for Phones */
@media (max-width: 768px) {
  .testimonials h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .testimonials-container {
    grid-template-columns: 1fr; 
  }

  .testimonial-item {
    font-size: 1rem;
    padding: 15px; 
    position: relative;
  }

  .testimonial-photo {
    width: 80px;
    height: 80px;
  }

  .testimonial-item p {
    font-size: 1rem; 
  }

  .testimonial-item h4 {
    font-size: 0.9rem; 
  }
}

@media (max-width: 480px) {
  .header-content h1 {
    font-size: 2rem;
  }

  .header-intro {
    font-size: 1.2rem;
  }

  .cta-btn {
    width: 90%;
    padding: 0.8rem 1.2rem;
  }

  .header-image img {
    width: 240px;
    height: 300px;
  }

  .header-image::before {
    height: 300px;
    width: 240px;
  }
}
