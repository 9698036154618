.projects {
  background-color: #1e1e1e;
  color: #f1f1f1;
  padding: 80px 0;
  text-align: center;
}

.projects h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #66fcf1;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  background-color: transparent;
  color: #f1f1f1;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #66fcf1; 
  color: #1e1e2e; 
  border-radius: 20px;
}

.projects-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 22px;
}

.project-item {
  position: relative;
  background-color: #2a2a3c;
  padding: 50px 20px;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  height: 300px;
}

.project-item:hover {
  transform: translateY(-10px);
}

.project-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: opacity 0.3s ease;
}

.project-item:hover img {
  opacity: 0.3;
}

.project-info {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #9b9bb2;
  font-size: 1rem;
}
.project-name{
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
}

.project-footer {
  color: #f1f1f1;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.project-category {
  background-color: #66fcf1;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.project-rating,
.project-date {
  font-size: 0.9rem;
  margin-top: 5px;
  color: #9b9bb2;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; /* Horizontally center content */
  transition: opacity 0.5s ease;
}

.project-item:hover .project-overlay {
  opacity: 1;
}

.project-details {
  color: #f1f1f1;
  text-align: center;
  padding: 20px;
  font-size: 1rem;
}

.project-details h3 {
  margin-bottom: 10px;
  font-size: 1.7rem;
}

.project-details p {
  font-size: 1rem;
  margin-bottom: 15px; /* Add spacing between description and source code */
}

.source-code {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center text and icon */
}

.source-code p {
  margin-bottom: 10px; 
  color: grey;
}

.view-more {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #f1f1f1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  margin-top: 10px; /* Optional: add spacing between text and button */
}

.view-more:hover {
  transform: scale(1.1);
  color: #4bc9c1; /* Hover effect for buttons */
}

@media (max-width: 1024px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .project-item {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .project-item {
    height: 300px;
  }
}
