
.profile-card {
  background-color: #1b1b1b;
  border-radius: 10px;
  padding: 50px;
  text-align: center;
  color: white;
}

.profile-img-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}

.profile-name {
  font-size: 24px;
  font-weight: bold;
}

.profile-role {
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px rgba(212, 186, 186, 0.3);
  text-align: center;
  width: auto;
  padding: 0 15px;
}

.profile-info {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.info-item a {
  color: #fff;
  text-decoration: none;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.info-item a:hover {
  color: #17a2b8;
}

.info-item .fa {
  margin-right: 10px;
}
