.blog {
  background-color: #0d0d0d;
  color: #fff;
  padding: 70px 0;
  text-align: center;
}

.blog h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #66fcf1;
}

.swiper {
  width: 100%;
  padding: 20px 0;
}

.blog-item {
  background-color: #1e1e1e;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease;
  width: 100%;
}
.blog-item:hover{
  transform: scale(1.1);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-details {
  padding: 20px;
  text-align: left;
}

.blog-details h3 {
  font-size: 1.6rem;
  color: #ff69b4;
  margin-bottom: 10px;
}

.blog-details p {
  font-size: 1rem;
  color: #ccc;
}

.blog-meta {
  font-size: 0.9rem;
  color: #888;
  margin-top: 10px;
}

.blog-btn {
  background-color: #ff69b4;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blog-btn:hover {
  background-color: #e55e9d;
}

.swiper-pagination-bullet-active {
  background-color: #ff69b4;
}

/* Center slide scaling effect */
.swiper-slide {
  transition: transform 0.4s ease;
}

.swiper-slide-active-scale {
  transform: scale(1.1); /* Scale up the active slide */
}

/* Responsive styling for mobile screens */
@media (max-width: 768px) {
  .blog-item {
    width: 100%; /* Ensure full width on mobile */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  .blog-image {
    height: 150px; /* Adjust image height for mobile */
  }

  .blog-details h3 {
    font-size: 1.4rem; /* Smaller font size for mobile */
  }

  .blog-details p {
    font-size: 0.9rem; /* Smaller font size for mobile */
  }

  .blog-meta {
    font-size: 0.8rem; /* Smaller meta text */
  }

  .blog-btn {
    padding: 8px 16px; /* Adjust button padding for mobile */
  }
}
